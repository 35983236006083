<template src="./StartPage.html"/>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "StartPage",
  components: { HeaderComponent },
  data() {
    return {
      loading: false,
      message: ''
    };
  },
  methods: {
    startGame() {
      this.loading = true;
      this.$store.dispatch('newGame')
          .then((data) => {
            if (data !== true) {
              this.loading = false;
              this.message = data;
            }
          });
    }
  },

};
</script>

<style scoped lang="scss">
@import 'StartPage.scss';
</style>
