<div class="game-wrapper">
    <div class="gameview">
        <SolutionInput/>
        <InventoryList/>
        <div class="resetControls">
            <button class="themed" @click="resetGame">Start over</button>
        </div>
    </div>
    <HintSystem/>
    <ItemModal/>
</div>
