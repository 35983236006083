<div class="hintsystem" :class="{active: hintVisible}">
    <div class="loading" :class="{active: isLoading}">
        <div class="rotation"></div>
    </div>
    <div class="content">
        <div>
            <button class="themed" @click="closeHint">Close</button>
        </div>
        <h2>Hints</h2>
        <p>The last hint might be the solution (or rarely, the password).</p>
        <button class="themed" @click="loadHints">Fetch hints for current day</button>
        <div class="hints">
            <div v-for="(dayData, dayIndex) in hints" class="hint-buttons">
                <h3>{{ dayIndex }}</h3>
                <div class="hint-buttons-container">
                    <button class="themed" v-for="(hint, hintIndex) in dayData" @click="showHint(dayIndex, hintIndex)">
                        Hint
                        {{ hintIndex + 1 }}
                    </button>
                </div>
            </div>
        </div>
        <p class="current-hint" v-html="currentHint"></p>
    </div>
</div>
