<template>
  <div class="inventorylist-wrapper">
    <h2>Inventory</h2>
    <div class="inventory-size">
      <input type="radio" id="size-small" v-model="inventorySize" value="size-small" name="inventorySize">
      <label for="size-small">
        <img src="@/assets/size-small.svg" alt="Small size" width="20" height="20" loading="lazy"/>
      </label>
      <input type="radio" id="size-medium" v-model="inventorySize" value="size-medium" name="inventorySize">
      <label for="size-medium">
        <img src="@/assets/size-medium.svg" alt="Medium size" width="20" height="20" loading="lazy"/>
      </label>
      <input type="radio" id="size-large" v-model="inventorySize" value="size-large" name="inventorySize">
      <label for="size-large">
        <img src="@/assets/size-large.svg" alt="Large size" width="20" height="20" loading="lazy"/>
      </label>
    </div>
    <transition-group tag="div" name="fade" class="inventorylist" :class="inventorySize">
      <InventoryItem v-for="item in inventory" :key="item.id" v-bind="item"/>
    </transition-group>
    <!--    <p>(click item to show larger version)</p>-->

  </div>
</template>

<script>
import InventoryItem from "@/components/InventoryItem/InventoryItem.vue";

export default {
  name: "InventoryList",
  components: { InventoryItem },
  data() {
    return {
      inventorySize: 'size-medium'
    };
  },
  computed: {
    inventory() {
      return this.$store.getters.inventory;
    }
  },
  watch: {
    inventorySize(newSize) {
      localStorage.setItem('inventorySize', newSize);
    }
  },
  mounted() {
    this.inventorySize = localStorage.getItem('inventorySize') || 'size-medium';
  }
};
</script>

<style scoped lang="scss">
.inventory-size {
  margin: 15px 0;
  display: grid;
  justify-content: center;
  grid-template-columns: max-content max-content max-content;


  --bg-color: #fff;

  input {
    display: none;

    &:checked + label {
      --bg-color: #ab1b1b;
    }
  }

  label {
    cursor: pointer;
    padding: 5px 10px;
    background: var(--bg-color);
    transition: 0.2s;

    &:hover {
      opacity: 0.8;
    }

    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      border-radius: 0 8px 8px 0;
    }

    img {
      display: block;
    }
  }
}

.inventorylist {
  &.size-large {
    --size: 550px;
  }

  &.size-medium {
    --size: 400px;
  }

  &.size-small {
    --size: 200px;
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, var(--size)), calc(var(--size) * 1.5)));
  gap: 10px;
  //@media screen and (max-width: 1500px) {
  //  grid-template-columns: repeat(5, 1fr);
  //}
  //@media screen and (max-width: 900px) {
  //  grid-template-columns: repeat(4, 1fr);
  //}
  //@media screen and (max-width: 800px) {
  //  grid-template-columns: repeat(3, 1fr);
  //}
  //@media screen and (max-width: 550px) {
  //  grid-template-columns: repeat(2, 1fr);
  //}
  //@media screen and (max-width: 400px) {
  //  grid-template-columns: repeat(1, 1fr);
  //}

}

p,
h2 {
  padding: 0;
  margin: 10px 0;
}
</style>
