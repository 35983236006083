<template>
  <div class="controls" v-if="openday < 24">
    <div>
      Solve the {{ day }} day
    </div>
    <div>
      <form action="" @submit.prevent="answer">
        <div>
          <input type="text" v-model="solution" ref="answerInput" :disabled="isLoading" required>
        </div>
        <div>
          <button class="themed-smaller" type="submit" :disabled="isLoading">
            <span class="spinner" v-if="isLoading"></span>
            <span v-if="!isLoading">Answer</span>
          </button>
        </div>
        <div>
          <button class="hint" type="button" :disabled="isLoading" @click="openHint">?</button>
        </div>
      </form>
    </div>
    <div class="feedback-wrapper">
      <Transition name="feedback">
        <div class="feedback error" v-if="error">
          <p>{{ error }}</p>
        </div>
      </Transition>
      <Transition name="feedback">
        <div class="feedback message" v-if="message">
          <p>{{ message }}</p>
        </div>
      </Transition>
    </div>


  </div>
</template>

<script>

export default {
  name: "SolutionInput",
  data() {
    return {
      solution: '',
      error: '',
      message: '',
      isLoading: false
    };
  },
  computed: {
    openday() {
      return this.$store.getters.lastOpen;
    },
    day() {
      const day = this.$store.getters.lastOpen + 1;
      return day + this.suffix(day);
    },
    whichMessage() {
      if (this.error) {
        return this.error;
      }
      if (this.message) {
        return this.message;
      }
      return '';
    },
    showFeedback() {
      return this.message || this.error;
    },
    feedbackClass() {
      if (this.error) {
        return 'error';
      }
      if (this.message) {
        return 'message';
      }
      return 'inactive';
    }
  },
  methods: {
    suffix(n) {
      return n > 3 && n < 21 ? "th" : n % 10 == 1 ? "st" : n % 10 == 2 ? "nd" : n % 10 == 3 ? "rd" : "th";
    },
    openHint() {
      this.$store.dispatch('toggleHint');
    },
    answer() {
      this.message = '';
      this.error = '';
      this.isLoading = true;
      this.$store.dispatch('solveDay', this.solution)
          .then((data) => {
            if (data.message) {
              this.message = data.message;
              this.solution = '';
            } else if (data.error) {
              this.error = data.error;
            }
          })
          .finally(() => {
            this.isLoading = false;
            console.log('width: ', window.innerWidth);
            if (window.innerWidth > 500) {
              //Large enough screen, give focus to the input box
              setTimeout(() => {
                this.$refs.answerInput.focus();
              }, 75);
            }
          })
      ;
    }
  }
};
</script>

<style scoped lang="scss">
.controls {
  --color-error: #ffda6a;
  --color-error-border: #997404;
  --color-error-bg: #332701;

  --color-message: #75b789;
  --color-message-border: #0f5132;
  --color-message-bg: #051b11;

  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: max-content max-content 75px;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  gap: 15px;


  .feedback {
    border-radius: 8px;
    border: 1px solid transparent;

    p {
      padding: 15px 10px;
      margin: 0;
    }

    &.message {
      border-color: var(--color-message-border);
      color: var(--color-message);
      background: var(--color-message-bg);
    }

    &.error {
      border-color: var(--color-error-border);
      color: var(--color-error);
      background: var(--color-error-bg);

    }
  }

  form {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    gap: 10px;

    input {
      width: 100%;
      padding: 5px;
      box-sizing: border-box;
    }

    button {
      box-sizing: border-box;
      text-align: center;
      padding: 5px;
      cursor: pointer;

      &.hint {
        background: #c0fcc0;
        border-radius: 100%;
        border-color: transparent;
        //color: #fff;
        width: 30px;
        font-weight: bold;
      }
    }

    button:not(.hint) {
      width: 65px;

      .spinner {
        display: inline-block;
        border-radius: 100px;
        width: 8px;
        height: 8px;
        border: 2px solid #fff;
        border-bottom-color: transparent;
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
      }
    }
  }
}

.feedback-enter-active {
  transition: all 0.2s ease-out;
}

.feedback-leave-active {
  transition: all 0.2s;
}

.feedback-enter-from,
.feedback-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
