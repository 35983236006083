<div class="startpage">
    <div class="introtext">
        Solve all the days to get the coordinates to the physical container.<br>
        You can click on items to show a larger version.
    </div>

    <div class="startgame" v-if="!loading">
        <div class="buttons">
            <button class="themed" @click="startGame(0)">Start the game</button>
        </div>
    </div>
    <div v-else>
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div v-if="message" v-html="message"></div>
</div>
