<template>
  <div class="header">
    <div class="text">
      <h1 v-html="title"></h1>
      <p><a :href="gcWebsite" target="_blank">{{ gcWebsite }}</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  computed: {
    logo() {
      return process.env.VUE_APP_LOGO;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    gcWebsite() {
      return 'https://coord.info/' + process.env.VUE_APP_GCCODE;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  max-width: 400px;
  gap: 20px;
  margin: 0 auto;
}

img {
  max-width: 120px;
  height: auto;
}

h1 {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
</style>
