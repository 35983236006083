<template src="./HintSystem.html"/>

<script>
export default {
  name: "HintSystem",
  data() {
    return {
      isLoading: false,
      currentHint: '',
      hints: {
        'Generic': [
          "Read 'Useful information' more than once (when you've gotten it), it can never hurt<br>(but it doesn't contain any direct solution to any day!).<br><br>The note 'Geocaching' is critical!<br>The note 'Geocaching' really is critical for the majority of the days.",
          //"For most hints, the first will tell you how many images you need to solve the day, the second hint which images and the 3rd hint how many words the password is.<br>That's not always the case, but for most days.",
          "Any image containing a prominent 'Day X' will be the central image to solve day X (some are a bit sneaky).<br>Most related images will something to indicate that they belong together.<br>If the current day requires more than one images then one of the hints for the day will let you know which images are required for that day."
        ]
      }
    };
  },
  computed: {
    hintVisible() {
      return this.$store.getters.hintVisible;
    }
  },
  methods: {
    closeHint() {
      this.$store.dispatch('toggleHint');
    },
    showHint(dayIndex, hintIndex) {
      this.currentHint = this.hints[dayIndex][hintIndex];
    },
    async loadHints() {
      this.isLoading = true;
      const hints = await this.$store.dispatch('getHint');
      this.hints = { Generic: this.hints.Generic };
      for (const day in hints) {
        this.hints[day] = hints[day];
      }
      this.isLoading = false;
    }
  },
  mounted() {
    document.addEventListener('keydown', (evt) => {
      if (evt.code === "Escape" && this.hintVisible) {
        this.$store.dispatch('toggleHint');
      }
    });
  }
};
</script>

<style scoped lang="scss">
@import 'HintSystem.scss';
</style>
